import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import '../CSS/Datenschutz.css';
import {withRouter} from "react-router-dom";


class Datenschutz extends Component{
    render() {
        return(
            <div>
                <Header titel="Datenschutz" page={"Datenschutzerklärung"}/>
                <div className={"div-datenschutz"}>
                    <label className={"label-uberschrift-datenschutz"}>
                        Datenschutzerklärung
                    </label>

                    <label className={"label-kategorie-datenschutz"}>
                        Verantwortlich:
                    </label>
                    <label className={"label-datenschutz"}>
                        Sabine Jarius
                        <br/>
                        Waldbröler Str. 28
                        <br/>
                        51570 Windeck
                    </label>

                    <br/>
                    <br/>

                    <label className={"label-kategorie-datenschutz"}>
                        Server-Log-Files
                    </label>

                    <label className={"label-datenschutz"}>
                        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                        <ul className={"ul-datenschutz"}>
                            <li>
                                Browsertyp und Browserversion
                            </li>
                            <li>
                                verwendetes Betriebssystem
                            </li>
                            <li>
                                Referrer URL
                            </li>
                            <li>
                                Hostname des zugreifenden Rechners
                            </li>
                            <li>
                                Uhrzeit der Serveranfrage
                            </li>
                        </ul>
                        Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
                    </label>

                    <br/>
                    <br/>



                    <label className={"label-kategorie-datenschutz"}>
                        Widerspruch Werbe-Mails
                    </label>
                    <label className={"label-datenschutz"}>
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                        <br/>
                        <br/>
                        Quelle: https://www.e-recht24.de
                    </label>

                    <br/>
                    <br/>



                    <label className={"label-kategorie-datenschutz"}>
                        Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    </label>
                    <label className={"label-datenschutz"}>
                        Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                        <br/>
                        <br/>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                    </label>





                </div>
                <Footer/>
            </div>

        );
    }

}

export default withRouter(Datenschutz);