import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import '../CSS/Leistungen.css';
import {withRouter} from "react-router-dom";
import Behandlungszimmer2 from '../Bilder/Behandlungszimmer2.JPG';


class Leistungen extends Component{
    render() {
        return(
            <div >
                <Header titel="Leistungen" page={"Leistungen"}/>
                <div className={"div-background-grey"}>
                    <div className={"div-grid-0"}>
                        <img className={"img"} src={Behandlungszimmer2} alt={"Foto Behandlungszimmer"}/>
                        <div className={"div-grid-1"}>
                            <label className={"label-uberschrift-green"}>
                                Leistungen
                            </label>
                            <ul className={"div-li-leistungen"}>
                                <li>
                                    24- Stunden-EKG
                                </li>
                                <li>
                                    24 -  Stunden-Blutdruckmessung
                                </li>
                                <li>
                                    Lungenfunktionsprüfung
                                </li>
                                <li>
                                    Ultraschall (Bauch, Schilddrüse)
                                    Vorsorgeuntersuchungen
                                </li>
                                <li>
                                    Gesundheitsuntersuchungen für Erwachsene und Jugendliche
                                </li>
                                <li>
                                    Hautkrebsvorsorge
                                </li>
                                <li>
                                    Krebsvorsorge für Männer
                                </li>
                                <li>
                                    Untersuchungen für
                                    Gutachten und Atteste
                                </li>
                                <li>
                                    Kur- und Rentenanträge
                                </li>
                                <li>
                                    Lebensversicherungen
                                </li>
                                <li>
                                    Gesundheitszeugnisse
                                </li>
                                <li>
                                    Impfungen
                                </li>
                                <li>
                                    Hausbesuche
                                </li>
                                <li>
                                    Schulungen  für Diabetes mellitus Typ 2, Hypertonie, KHK, Asthma und  COPD
                                </li>
                                <li>
                                    Akupunktur
                                </li>
                                <li>
                                    Kinesiotherapie
                                </li>
                                <li>
                                    IMD-Messung
                                </li>
                                <li>
                                    Infektionsschutzbelehrung nach §43 Abs. 1 Nr. 2 Infektionsgesetz
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <Footer/>
            </div>

        );
    }

}

export default withRouter(Leistungen);