import '../CSS/DesktopMenu.css';
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";



class DesktopMenu extends Component{

    zuStart = () =>{
        this.props.history.push("/")
    }

    zuUnserTeam = () =>{
        this.props.history.push("/team")
    }

    zuLeistungen = () =>{
        this.props.history.push("/leistungen")
    }

    zuAkupuntur = () =>{
        this.props.history.push("/akupunktur")
    }

    zuRezepte = () =>{
        this.props.history.push("/rezepte")
    }

    zuHausbesuche = () =>{
        this.props.history.push("/hausbesuche")
    }

    zuSprechzeiten = () =>{
        this.props.history.push("/sprechzeiten")
    }

    zuDatenschutz = () =>{
        this.props.history.push("/datenschutz")
    }

    render() {
        return(
            <div>
                <div className={"div-nav-desktop"}>
                    <button className={this.props.page === "Start" ?"button-nav-desktop-aktiv":"button-nav-desktop"} onClick={this.zuStart}>Start</button>
                    <button className={this.props.page === "Unser Team" ?"button-nav-desktop-aktiv":"button-nav-desktop"} onClick={this.zuUnserTeam}>Unser Team</button>
                    <button className={this.props.page === "Leistungen" ?"button-nav-desktop-aktiv":"button-nav-desktop"} onClick={this.zuLeistungen}>Leistungen</button>
                    <button className={this.props.page === "Akupunkt" ?"button-nav-desktop-aktiv":"button-nav-desktop"} onClick={this.zuAkupuntur}>Akupunktur</button>
                    <button className={this.props.page === "Rezepte" ?"button-nav-desktop-aktiv":"button-nav-desktop"} onClick={this.zuRezepte}>Rezepte</button>
                    <button className={this.props.page === "Hausbesuche" ?"button-nav-desktop-aktiv":"button-nav-desktop"} onClick={this.zuHausbesuche}>Hausbesuche</button>
                    <button className={this.props.page === "Sprechzeiten" ?"button-nav-desktop-aktiv":"button-nav-desktop"} onClick={this.zuSprechzeiten}>Sprechzeiten</button>
                    <button className={this.props.page === "Datenschutzerklärung" ?"button-nav-desktop-aktiv":"button-nav-desktop"} onClick={this.zuDatenschutz}>Datenschutzerklärung</button>
                </div>

            </div>

        );
    }
}

export default withRouter(DesktopMenu);