import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import '../CSS/Start.css';
import {withRouter} from "react-router-dom";
import Eingang1 from '../Bilder/Eingang1.jpg';
import Wartezimmer from '../Bilder/Wartezimmer.jpg';
import Team from '../Bilder/Team.jpg';
import Bezahndlungszimmer from '../Bilder/Behandlungszimmer.JPG';



class Start extends Component{
    render() {
        return(
            <div>
                <Header page={"Start"}/>
                <div className={"div-background-grey"}>
                    <div className={"div-grid-0"}>
                        <div className={"div-grid-1"}>
                            <label className={"label-uberschrift-green"}>
                                Herzlich Willkommen in unserer Praxis
                            </label>
                            <label className={"label-text-grey"}>
                                Wir begrüßen Sie herzlich in unserer Praxis und möchten, dass Sie sich bei uns gut aufgehoben fühlen. Mit dieser Internet-Seite möchten wir Ihnen Einblick in unsere Praxis geben. Sollten Sie Fragen oder ein besonderes Anliegen haben, wenden Sie sich gerne an ein Mitglied unseres Teams. Für Verbesserungsvorschläge sind wir stets dankbar.
                            </label>
                        </div>
                        <img className={"img"} src={Eingang1} alt={"Foto Willkommen"}/>
                    </div>
                </div>
                <div className={"div-backround-white"}>
                    <label className={"label-uberschrift-green-1"}>
                        Aktuelle Informationen
                    </label>
                    <label className={"label-urlaubstage-start"}>
                        Urlaubstage 2024
                    </label>
                    <label>
                        vom 11.03 bis 24.03
                    </label>
                    <label>
                        vom 06.05 bis 19.05
                    </label>
                    <label>
                        vom 08.08 bis 18.08
                    </label>
                    <label>
                        vom 16.12 bis 31.12
                    </label>
                    <br/>
                </div>

                <div className={"div-backround-img-start"}>
                    <img className={"img-1-start"} src={Wartezimmer} alt={"Foto Wartezimmer"}/>
                    <img className={"img-1-start"} src={Team} alt={"Foto Team"}/>
                    <img className={"img-1-start"} src={Bezahndlungszimmer} alt={"Foto Behandlungszimmer"}/>

                </div>
                <Footer/>
            </div>

        );
    }

}

export default withRouter(Start);
