import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import './App.css';
import Start from "./UI/Start";
import UnserTeam from "./UI/UnserTeam";
import Leistungen from "./UI/Leistungen";
import Akupunktur from "./UI/Akupunktur";
import Rezepte from "./UI/Rezepte";
import Hausbesuche from "./UI/Hausbesuche";
import Sprechzeiten from "./UI/Sprechzeiten";
import Datenschutz from "./UI/Datenschutz";


class App extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path={"/"} exact component={Start}/>
                    <Route path={"/team"} component={UnserTeam}/>
                    <Route path={"/leistungen"} component={Leistungen}/>
                    <Route path={"/akupunktur"} component={Akupunktur}/>
                    <Route path={"/rezepte"} component={Rezepte}/>
                    <Route path={"/hausbesuche"} component={Hausbesuche}/>
                    <Route path={"/sprechzeiten"} component={Sprechzeiten}/>
                    <Route path={"/datenschutz"} component={Datenschutz}/>
                </Switch>
            </div>
        );
    }
}

export default App;