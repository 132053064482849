import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import '../CSS/Hausbesuche.css';
import {withRouter} from "react-router-dom";
import SabineAuto from '../Bilder/autosabine.jpg';


class Hausbesuche extends Component{
    render() {
        return(
            <div>
                <Header titel="Hausbesuche" page={"Hausbesuche"}/>
                <div className={"div-background-grey"}>
                    <div className={"div-grid-0"}>
                        <img className={"img"} src={SabineAuto} alt={"Foto Hausbesuche"}/>

                        <div className={"div-grid-1"}>
                            <label className={"label-uberschrift-green"}>
                                Hausbesuche
                            </label>
                            <label className={"label-text-grey"} >
                                Hausbesuche finden in der Regel während der sprechstundenfreien Zeiten am Dienstagnachmittag statt. Sprechen Sie bitte möglich bis 10:00 uhr mit uns über die Notwendigkeit eines Hausbesuches.
                                <br/>
                                <br/>
                                In einigen Fällen werden Hausbesuche von der entlastenden Versorgungsassistentin durchgeführt.
                            </label>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }

}

export default withRouter(Hausbesuche);