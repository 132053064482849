import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import '../CSS/Sprechzeiten.css';
import {withRouter} from "react-router-dom";
import Sabine1 from '../Bilder/Sabine1.jpg';


class Sprechzeiten extends Component{
    render() {
        return(
            <div>
                <Header titel="Sprechzeiten" page={"Sprechzeiten"}/>
                <div className={"div-background-grey"}>
                    <div className={"div-grid-0"}>
                        <div className={"div-grid-1"}>

                            <label className={"label-uberschrift-green"}>
                                Unsere Sprechstundenzeiten
                            </label>
                            <label className={"label-text-grey"}>
                                Um unnötige Wartezeiten für Sie zu vermeiden, möchten wir unsere Patienten bitten Termine zu vereinbaren.
                                <br/>
                                Bitte melden Sie sich in der Notfallsprechstunde auch telefonisch an.
                            </label>


                            <label className={"label-uberschrift-green-1"}>
                                Die Terminsprechstunden:
                            </label>

                            <div className={"div-grid-zeiten"}>
                                <label className={"label-text-grey"}>
                                    Montag
                                </label>
                                <label className={"label-text-grey"}>
                                    09.00 Uhr bis 12.00 Uhr
                                </label>
                                <label className={"label-text-grey"}>
                                    Dienstag- Freitag
                                </label>
                                <label className={"label-text-grey"}>
                                    08.00 Uhr bis 11.00 Uhr
                                </label>
                                <label className={"label-text-grey"}>
                                    Donnerstag
                                </label>
                                <label className={"label-text-grey"}>
                                    15.00 Uhr bis 18.00 Uhr
                                </label>

                            </div>
                            <br/>
                            <label className={"label-uberschrift-green-1"}>
                                Sprechzeiten für Patienten mit akuten Beschwerden oder ohne Termin:
                            </label>


                            <div className={"div-grid-zeiten"}>
                                <label className={"label-text-grey"}>
                                    Montag
                                </label>
                                <label className={"label-text-grey"}>
                                    08.00 Uhr bis 09.00 Uhr
                                    <br/>
                                    12.00 Uhr bis 13.00 Uhr
                                </label>
                                <label className={"label-text-grey"}>
                                    Dienstag- Freitag
                                </label>
                                <label className={"label-text-grey"}>
                                    11.00 Uhr bis 13.00 Uhr
                                </label>
                            </div>
                        </div>
                        <img className={"img"} src={Sabine1} alt={"Foto Sabine Jarius"}/>
                    </div>
                </div>


                <Footer/>
            </div>

        );
    }

}

export default withRouter(Sprechzeiten);