import React, {Component} from 'react';
import '../CSS/Akupuntur.css';
import Header from "./Header";
import Footer from "./Footer";
import {withRouter} from "react-router-dom";
import Akupunkt from '../Bilder/akupunktur.JPG';


class Akupunktur extends Component{
    render() {
        return(
            <div>
                <Header titel="Akupunktur" page={"Akupunkt"}/>

                <div className={"div-background-grey"}>
                    <div className={"div-grid-0"}>
                        <img className={"img"} src={Akupunkt} alt={"Foto Akupunkturzimmer"}/>
                        <div className={"div-grid-1"}>

                            <label className={"label-uberschrift-green"}>
                                Akupunktur
                            </label>
                            <label className={"label-text-grey label-akupunkt-underline"}>
                                Akupunktur kann zerstörte Strukturen nicht wiederherstellen, wohl aber gestörte Funktionen harmonisieren
                            </label>

                            <label className={"label-text-grey"}>
                                Die Reizung von Akupunkturpunkten mit Nadeln, ist wohl die älteste und am weitesten verbreitete Heilmethode der Welt. Sie existiert seit über 3000 Jahren und ist eine auf Erfahrung  beruhende Heilweise, die unverändert über Jahre ihre Wirkung bewiesen hat.
                                <br/>
                                <br/>
                                Aufgrund naturwissenschaftlicher Erkenntnisse der letzten Jahrzehnte wissen wir heute, dass die Akupunktur auf einem komplexen Wirkprinzip beruht, das sich u.a. in der Wechselwirkung zwischen inneren Organen und Muskelzonen mit der Körperoberfläche wieder findet.
                                <br/>
                                <br/>
                                Grundlage bleibt der Einklang von Körper, Geist und Seele.
                                <br/>
                                <br/>
                                Die WHO empfiehlt die Akupunktur bei einer Vielzahl von Erkrankungen. Außer bei schmerzhaften Erkrankungen des Bewegungsapparates, wird sie z.B. auch bei Kopfschmerzen, Magen-Darmbeschwerden, allergischen Erkrankungen, Zahnschmerzen, Erschöpfungszustände, Nikotinentwöhnung, u.n.v.m. empfohlen.
                                <br/>
                                <br/>
                                Die Akupunkturbehandlung wird bei Schmerzen der Lendenwirbelsäue und/oder der Knie, die länger als 6 Monate bestehen von der Krankenkasse übernommen. Bei allen anderen Erkrankungen oder Beschwerden ist die Akupunktur keine Kassenleistung.
                                <br/>
                                <br/>
                                Private Krankenkassen übernehmen die Kosten für eine Schmerzbehandlung in der Regel vollständig, da die Akupunktur in die Gebührenordnung für Ärzte (GOÄ) aufgenommen worden ist.
                            </label>
                        </div>

                    </div>

                </div>
                <Footer/>
            </div>

        );
    }

}

export default withRouter(Akupunktur);