import '../CSS/BurgerMenu.css';
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

class BurgerMenu extends Component{

    zuStart = () =>{
        this.props.history.push("/")
    }

    zuUnserTeam = () =>{
        this.props.history.push("/team")
    }

    zuLeistungen = () =>{
        this.props.history.push("/leistungen")
    }

    zuAkupuntur = () =>{
        this.props.history.push("/akupunktur")
    }

    zuRezepte = () =>{
        this.props.history.push("/rezepte")
    }

    zuHausbesuche = () =>{
        this.props.history.push("/hausbesuche")
    }

    zuSprechzeiten = () =>{
        this.props.history.push("/sprechzeiten")
    }

    zuDatenschutz = () =>{
        this.props.history.push("/datenschutz")
    }

    render() {
        return(
            <div>
                <div className={"div-nav-burgermenu"}>
                    <nav role="navigation">
                        <div id="menuToggle">
                            <input type="checkbox"/>
                            <span></span>
                            <span></span>
                            <span></span>
                            <ul id="menu">
                                <li onClick={this.zuStart}>Start</li>
                                <li onClick={this.zuUnserTeam}>Unser Team</li>
                                <li onClick={this.zuLeistungen}>Leistungen</li>
                                <li onClick={this.zuAkupuntur}>Akupunktur</li>
                                <li onClick={this.zuRezepte}>Rezepte</li>
                                <li onClick={this.zuHausbesuche}>Hausbesuche</li>
                                <li onClick={this.zuSprechzeiten}>Sprechzeiten</li>
                                <li onClick={this.zuDatenschutz}>Datenschutzerklärung</li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}
export default withRouter(BurgerMenu);