import React, {Component} from 'react';
import '../CSS/UnserTeam.css';
import Header from "./Header";
import Footer from "./Footer";
import {withRouter} from "react-router-dom";
import Team2 from '../Bilder/Team2.jpg';
import Sabine from '../Bilder/Sabine.jpg';
import Gabriele from '../Bilder/Gaby.jpg';
import Aynur from '../Bilder/Aynur2.jpg';
import Yasemin   from '../Bilder/Yaso.jpg';
import Leila from '../Bilder/Leila.jpg';


class UnserTeam extends Component{
    render() {
        return(
            <div>
                <Header titel="Unser Team" page={"Unser Team"}/>
                <div className={"div-backround-white"}>
                    <label className={"label-uberschrift-green"}>
                        Unser Team
                    </label>
                    <img className={"img-1-team"} src={Team2} alt={"Foto Team1"}/>

                </div>
                <div className={"div-sabine-lebenslauf"}>
                    <div className={"div-sabine-lebenslauf-1"}>
                        <img className={"img-sabine"} src={Sabine} alt={"Foto Sabine Jarius"}/>
                        <label className={"label-sabine-name-team"}>
                            Sabine Jarius
                        </label>
                        <label className={"label-sabine-1"}>
                            Fachärztin für Allgemeinmedizin
                            <br/>
                            Palliativmedizin
                            <br/>
                            Akupunktur
                            <br/>
                            Diplom-Sozialpädagogin
                        </label>
                    </div>

                    <div className={"grid-table"}>

                        <label className={"label-lebenslauf-team"}>
                            Lebenslauf
                        </label>
                        <label></label>
                        <label className={"label-grid-team"}>
                            1989
                        </label>
                        <label className={"label-grid-team"}>
                            Abitur am THG Kerpen
                        </label>


                        <label className={"label-grid-team"}>
                            08/89 - 06/90
                        </label>

                        <label className={"label-grid-team"}>
                            Praktikum (FSJ) Soziokultureller Dienst des Stahl`schen Stifts, ein Alten- und Pflegeheim der Caritas
                        </label>

                        {/*----------------------------*/}
                        <label className={"label-abschnitt-lebenslauf"}>
                            Studium
                        </label>
                        <label></label>


                        <label className={"label-grid-team"}>
                            10/90 - 01/94
                        </label>
                        <label className={"label-grid-team"}>
                            Sozialpädagogik an der KFH Köln
                            <br/>
                            Abschluß: Diplom-Sozialpädagogin
                        </label>


                        <label className={"label-grid-team"}>
                            04/92 - 03/98
                        </label>

                        <label className={"label-grid-team"}>
                            Medizin an der Universität zu Köln
                        </label>

                        <label className={"label-grid-team"}>
                            04/98 - 03/99
                        </label>

                        <label className={"label-grid-team"}>
                            Praktisches Jahr im KKH-Waldbröl
                        </label>



                        {/*----------------------------*/}
                        <label className={"label-abschnitt-lebenslauf"}>
                            Berufliche Entwicklung
                        </label>
                        <label></label>



                        <label className={"label-grid-team"}>
                            05/99 - 11/00
                        </label>
                        <label className={"label-grid-team"}>
                            Ärztin im Praktikum in der Allgemeinchirurgie,
                            <br/>
                            Inneren Medizin Abteilung Pulmologie,
                            <br/>
                            Hämatologie, Onkologie im KKH Waldbröl
                        </label>




                        <label className={"label-grid-team"}>
                            11/00 –09/01
                        </label>
                        <label className={"label-grid-team"}>
                            Assistenzärztin in der Inneren Medizin im KKH-Waldbröl Abteilung Kardiologie
                        </label>



                        <label className={"label-grid-team"}>
                            10/01 – 03/03
                        </label>
                        <label className={"label-grid-team"}>
                            Weiterbildungsassistentin in einer Praxis für Allgemeinmedizin in Wissen
                        </label>


                        <label className={"label-grid-team"}>
                            04/03- 09/04
                        </label>
                        <label className={"label-grid-team"}>
                            Weiterbildungsassistentin in einer Praxis für Allgemeinmedizin, Pädiatrie und diabetologische Schwerpunktpraxis in Betzdorf
                        </label>




                        <label className={"label-grid-team"}>
                            10/04
                        </label>
                        <label className={"label-grid-team"}>
                            Facharztprüfung Allgemeinmedizin
                        </label>



                        <label className={"label-grid-team"}>
                            06/07
                        </label>
                        <label className={"label-grid-team"}>
                            B-Diplom Akupunktur
                        </label>


                        <label className={"label-grid-team"}>
                            04/05 - 12/14
                        </label>
                        <label className={"label-grid-team"}>
                            Niedergelassene Allgemeinmedizinerin in einer Gemeinschaftspraxis mit Dr. Gunther Köster
                        </label>




                        <label className={"label-grid-team"}>
                            2007
                        </label>
                        <label className={"label-grid-team"}>
                            Zusatzbezeichnung Akupunktur und Palliativmedizin
                        </label>



                        <label className={"label-grid-team"}>
                            seit 01.2015
                        </label>
                        <label className={"label-grid-team"}>
                            Niedergelassene Allgemeinmedizinerin in Windeck-Schladern
                        </label>
                    {/*</div>*/}
                </div>
                </div>
                    {/*--------------------------------------------*/}

                    <hr className={"hr-horizontale-linie"}/>
                {/*<div>*/}
                    <div id={"mitarbeiter"} className={"div-mitarbeiter-team"}>
                        <div id={"gabriele"} className={"div-grid-img-team"}>
                            <img className={"img-ma-team"} src={Gabriele} alt={"Foto Gabriele Funken"}/>
                            <label className={"label-ma-name"}>
                                Funken Gabriele
                            </label>
                            <label className={"label-ma-info"}>
                                Sprachen:
                                <br/>
                                deutsch
                                <br/>
                                -
                                <br/>
                                Ausbildung zur medizinischen Fachangestellten
                                <br/>
                                von 1989-1991
                                <br/>
                                -
                                <br/>
                                seit 02.01.2015 Angestellte der Praxis Sabine Jarius
                                <br/>
                                -
                                <br/>
                                Schulungen für
                                <br/>
                                Diabetes mellitus, Hypertonie, KHK, Asthma, COPD
                                <br/>
                                -
                                <br/>
                                Entlastende Versorgungsassistentin
                            </label>

                        </div>

                        {/*--------------------------------------------*/}
                        <hr className={"hr-horizontale-linie"}/>

                        <div id={"aynus"} className={"div-grid-img-team"}>
                            <img className={"img-ma-team"} src={Aynur} alt={"Foto Aynur Özkoc"}/>
                            <label className={"label-ma-name"}>
                                Oezkoc Aynur
                            </label>
                            <label className={"label-ma-info"}>
                                Sprachen:
                                <br/>
                                deutsch, türkisch und kurdisch
                                <br/>
                                -
                                <br/>
                                Ausbildung zur medizinischen Fachangestellten
                                <br/>
                                von 1994-1997
                                <br/>
                                -
                                <br/>
                                seit 02.01.2015 Angestellte der Praxis Sabine Jarius

                            </label>

                        </div>

                        {/*--------------------------------------------*/}
                        <hr className={"hr-horizontale-linie"}/>


                        <div id={"yasemin"} className={"div-grid-img-team"}>
                            <img className={"img-ma-team"} src={Yasemin} alt={"Foto Yasemin Parlak"}/>
                            <label className={"label-ma-name"}>
                                Parlak Yasemin
                            </label>
                            <label className={"label-ma-info"}>
                                Sprachen:
                                <br/>
                                deutsch und türkisch
                                <br/>
                                -
                                <br/>
                                Ausbildung zur medizinischen Fachangestellten
                                <br/>
                                von 2009-2012
                                <br/>
                                -
                                <br/>
                                seit 02.01.2015 Angestellte der Praxis Sabine Jarius

                            </label>

                        </div>

                        {/*--------------------------------------------*/}
                        <hr className={"hr-horizontale-linie"}/>

                        <div id={"leila"} className={"div-grid-img-team"}>
                            <img className={"img-ma-team"} src={Leila}  alt={"Foto Leila Geska"}/>
                            <label className={"label-ma-name"}>
                                Geske Leila
                            </label>
                            <label className={"label-ma-info"}>
                                Sprachen:
                                <br/>
                                deutsch und englisch
                                <br/>
                                -
                                <br/>
                                Ausbildung zur medizinischen Fachangestellten
                                <br/>
                                von 2016 - 2018
                                <br/>
                                -
                                <br/>
                                seit 15.08.2018 Angestellte der Praxis Sabine Jarius
                            </label>

                        </div>
                    </div>

                    {/*--------------------------------------------*/}
                    <hr className={"hr-horizontale-linie"}/>


                {/*</div>*/}
                <Footer/>
            </div>

        );
    }

}

export default withRouter(UnserTeam);